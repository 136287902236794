/* eslint-disable max-len */

type Props = {
  iconClass?: string;
};
export const Location: React.FC<Props> = ({ iconClass }) => (
  <svg
    className={`menu__icon menu__icon--map ${iconClass}`}
    viewBox="0 0 32 32"
  >
    <g>
      <path
        clipRule="evenodd"
        d="M16.002,17.746c3.309,0,6-2.692,6-6s-2.691-6-6-6   c-3.309,0-6,2.691-6,6S12.693,17.746,16.002,17.746z M16.002,6.746c2.758,0,5,2.242,5,5s-2.242,5-5,5c-2.758,0-5-2.242-5-5   S13.244,6.746,16.002,6.746z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16,0C9.382,0,4,5.316,4,12.001c0,7,6.001,14.161,10.376,19.194   C14.392,31.215,15.094,32,15.962,32c0.002,0,0.073,0,0.077,0c0.867,0,1.57-0.785,1.586-0.805   c4.377-5.033,10.377-12.193,10.377-19.194C28.002,5.316,22.619,0,16,0z M16.117,29.883c-0.021,0.02-0.082,0.064-0.135,0.098   c-0.01-0.027-0.084-0.086-0.129-0.133C12.188,25.631,6,18.514,6,12.001C6,6.487,10.487,2,16,2c5.516,0,10.002,4.487,10.002,10.002   C26.002,18.514,19.814,25.631,16.117,29.883z"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
