import { useEffect, useRef } from 'react';
import { CardItem } from '../types/cardItem';

type Props = {
  card: CardItem;
  section: string;
  optonalClass?: string;
};

export const Card: React.FC<Props> = ({ card, section }) => {
  const cardEl = useRef<HTMLDivElement>(null);
  const img = useRef<HTMLImageElement>(null);
  const touch = useRef<HTMLImageElement>(null);

  function click() {
    if (cardEl.current && img.current) {
      const { src } = img.current;

      if (cardEl.current.classList.contains('card--hover')) {
        cardEl.current.classList.remove('card--hover');

        if (src.includes('2')) {
          img.current.src = `${src.slice(0, src.length - 5)}.jpg`;
        }
      } else {
        cardEl.current.classList.add('card--hover');

        if (!src.includes('2')) {
          img.current.src = `${src.slice(0, src.length - 4)}2.jpg`;
        }
      }
    }
  }

  //   if (cardEl.current && img.current) {
  //     const { src } = img.current;

  //     const onHover = () => {
  //       cardEl.current?.classList.add('card--hover');

  //       if (!src.includes('2') && img.current) {
  //         img.current.src = `${src.slice(0, src.length - 4)}2.jpg`;
  //       }
  //     }

  //     const onBlur = () => {
  //       cardEl.current?.classList.remove('card--hover');

  //       if (img.current) {
  //         img.current.src = src;
  //       }
  //     }

  //     const showTouch = () => {
  //       if (touch.current) {
  //         touch.current.style.display = 'block';
  //       }
  //     }

  //     const hideTouch = () => {
  //       if (touch.current) {
  //         touch.current.style.display = 'none';
  //       }
  //     }

  //     setTimeout(showTouch, 500);

  //     setTimeout(onHover, 700);

  //     setTimeout(hideTouch, 1200);

  //     setTimeout(showTouch, 2000);

  //     setTimeout(onBlur, 2200);

  //     setTimeout(hideTouch, 2400);
  //   }
  // };
  useEffect(() => {
    if (cardEl.current && img.current) {
      const { src } = img.current;

      const onHover = () => {
        cardEl.current?.classList.add('card--hover');

        if (!src.includes('2') && img.current) {
          img.current.src = `${src.slice(0, src.length - 4)}2.jpg`;
        }
      };

      const onBlur = () => {
        cardEl.current?.classList.remove('card--hover');

        if (img.current) {
          img.current.src = src;
        }
      };

      const showTouch = () => {
        if (touch.current) {
          touch.current.style.display = 'block';
        }
      };

      const hideTouch = () => {
        if (touch.current) {
          touch.current.style.display = 'none';
        }
      };

      const checkVisibility = () => {
        if (cardEl.current) {
          if (
            cardEl.current.getBoundingClientRect().top <
            window.innerHeight / 2
          ) {
            setTimeout(showTouch, 500);
            setTimeout(onHover, 700);
            setTimeout(hideTouch, 1200);
            setTimeout(showTouch, 2000);
            setTimeout(onBlur, 2200);
            setTimeout(hideTouch, 2400);

            window.removeEventListener('scroll', checkVisibility);
          }
        }
      };

      if (!window.matchMedia('(pointer: coarse)').matches) {
        cardEl.current.addEventListener('mouseover', onHover);
        cardEl.current.addEventListener('mouseout', onBlur);
        window.addEventListener('scroll', checkVisibility, { once: true });
      } else {
        window.addEventListener('scroll', checkVisibility);
      }
    }
  }, []);

  return (
    <div
      className={
        card.optionalClass
          ? `card ${section}__card ${card.optionalClass}`
          : `card ${section}__card`
      }
      ref={cardEl}
      onMouseDown={() => click()}
      role="button"
      tabIndex={0}
    >
      <div className={`${section}__card--photo${card.id} card__photo`}>
        <img
          className="card__photo--img"
          src={card.src}
          alt={card.alt && card.title}
          ref={img}
        />
        <img
          className="card__photo--touch"
          src="images/svg/touch.svg"
          alt="touch"
          ref={touch}
        />
      </div>

      <div className="card__description card__description--bento">
        <h3 className="card__title">{card.title}</h3>

        <p className="card__about">{card.about}</p>

        <p className="card__subtitle">
          {card.subtitle}

          {section !== 'desserts' && (
            <span className="bold">{`${card.price}`}</span>
          )}
        </p>
      </div>
    </div>
  );
};
